import { Get, Post } from './BaseService';
import Tema from '../models/Tema';
import Questao from '../models/Questao';
import RequestSimulado from '@/models/RequestSimulado';
import ResponseSimulado from '@/models/ResponseSimulado';
import EstatisticaTeorico from '@/models/EstatisticaTeorico';

export default class SimuladoTeoricoService {

    public static BaseUrl = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_SIMULADO_API_URL}`;
    
    public static GetTemas(): Promise<Tema[]> {
        return Get<Tema[]>(`${this.BaseUrl}/temas`, {
            pagina: 1,
            tamanhoPagina: 7
        });
    }

    public static GetQuestoes(quantidade: number, temas: number[]): Promise<Questao[]> {
        return Get<Questao[]>(`${this.BaseUrl}/temas/questoes`, {
            quantidade: quantidade,
            temaId: temas
        });
    }

    public static ResponderSimulado(requestSimulado: RequestSimulado): Promise<ResponseSimulado> {
        return Post<RequestSimulado, ResponseSimulado>(`${this.BaseUrl}/simulados`, requestSimulado);
    }

    public static GetEstatisticasTeorico(idUsuario: number): Promise<EstatisticaTeorico> {
        return Get<EstatisticaTeorico>(`${this.BaseUrl}/simulados/estatisticas/${idUsuario}`);
    }
}