import NotificationDTO from '@/modules/core/DTOs/notifications/NotificationDTO';
import NotificationsService from '@/modules/core/services/NotificationsService';
import { ActionContext, Module } from 'vuex';
import { RootState } from '..';

export interface CoreState {
    notifications: NotificationDTO[];
}

const coreStore: Module<CoreState, RootState> = {
    state: {
        notifications: [],
    },
    getters: {
        getNotifications(state: CoreState){
            return state.notifications;
        }
    },
    mutations: {
        setNotifications(state: CoreState, value: NotificationDTO[]){
            state.notifications = value;
        }
    },
    actions: {
        fetchNotifications({ commit }: ActionContext<CoreState, RootState>){
            return new Promise((resolve, reject) => {
                NotificationsService.getUnreadBySession()
                    .then((notifications: NotificationDTO[]) => {
                        commit('setNotifications', notifications);
                        resolve(notifications);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        readNotifications({ dispatch }: ActionContext<CoreState, RootState>, notifications: NotificationDTO[]){
            return new Promise((resolve, reject) => {
                NotificationsService.read(notifications)
                    .then(() => {
                        dispatch('fetchNotifications').then(() => {
                            resolve(notifications);
                        }).catch((error) => {
                            reject(error);
                        });
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    }
};

export default coreStore;
