import { Get } from '@/services/BaseService';
import ListAulasDTO from '@/models/ListAulasDTO';
import { AulaDetailDTO } from '@/DTOs/AulaDetailDTO';
import ClassRouteDTO from '@/DTOs/ClassRouteDTO';
import { ClassImageDTO } from '@/DTOs/ClassImageDTO';
import { SyncDTO } from '@/DTOs/SyncDTO';
export default class IntegrationService {
    
    public static BaseUrl = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_INTEGRACAO_API_URL}`;

    public static GetClasses(cpf: string, estados: string) {
        const ret = Get<ListAulasDTO>(`${this.BaseUrl}/aulas`, { cpf, estados });
        return ret;
    }

    public static GetClassDetail(oid: string, cpf: string, estado: string) {
        return Get<AulaDetailDTO>(`${this.BaseUrl}/${oid}/details`, { cpf, estado });
    }

    public static GetClassRoute(oid: string, cpf: string, estado: string) {
        return Get<ClassRouteDTO>(`${this.BaseUrl}/${oid}/maps`, { cpf, estado });
    }

    public static GetImage(oid: string, cpf: string, estado: string) {
        return Get<ClassImageDTO>(`${this.BaseUrl}/${oid}/imagens`, { cpf, estado });
    }

    public static Sync(cpf: string, estado: string) {
        return Get<SyncDTO>(`${this.BaseUrl}/sync`, { cpf, estado });
    }
}