import { Get, GetWithCanceler } from './BaseService';
import ResponseCfcDTO from '@/models/ResponseCfcDTO';
import GooglePlaceDetailDTO from '@/models/GooglePlaceDetailDTO';

export default class LocationService  {

    public static BaseUrl = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_BUSCA_API_URL}`;

    public static SearchAddress(location: string, position: { lat: number; lng: number } | null) {
        let query: { textoBusca: string; lat?: number ; lng?: number};

        const lat = position != null ? position.lat : null;
        const lng = position != null && lat != null ? position.lng : null;
        
        if(lat && lng) {
            query = { textoBusca: location, lat, lng };
        }
        else {
            query = { textoBusca: location };            
        }
        return GetWithCanceler<ResponseCfcDTO[]>(`${this.BaseUrl}/searchtext`, query );
    }

    public static GetCfcs(lat: number, lng: number, raio: number = 2000) {
        return Get<ResponseCfcDTO[]>(this.BaseUrl, {
            lat: lat,
            lng: lng,
            raio: raio
        });
    }

    public static GetGooglePlaceDetail(placeId: string){
        return Get<GooglePlaceDetailDTO>(`${this.BaseUrl}/detalhes/google-id/${placeId}`);
    }
}