import axios, { AxiosResponse, AxiosError, Canceler, AxiosInstance } from 'axios';
import $http from '@/plugins/http';

const DEFAULT_RESPONSE: string = 'Serviço indisponível no momento, tente novamente mais tarde!';

const CancelToken = axios.CancelToken;

let canceler: Canceler;

export const CancelRequest = (message?: string) => canceler && canceler(message || 'Operação cancelada');

export const Get = <M>(url: string = '', query?: { [key: string]: any }, options?: { customClient?: AxiosInstance }): Promise<M> => {

    // Pegar a query String
    return new Promise((resolve, reject) => {
        // Realizar requisição HTTP
        (options?.customClient || $http).get(url, { params: query, cancelToken: new CancelToken((c) => canceler = c) })
            .then((resp: AxiosResponse<M>) => {
                resolve(resp.data);
            })
            .catch((error: AxiosError) => {
                reject(error.response != null ? error.response.data : DEFAULT_RESPONSE);
            });
    });
};

export const Delete = <M>(url: string = ''): Promise<M> => {
    return new Promise((resolve, reject) => {
        // Realizar requisição HTTP
        $http.delete(url, { cancelToken: new CancelToken((c) => canceler = c) })
            .then((resp: AxiosResponse<M>) => {
                resolve(resp.data);
            })
            .catch((error: AxiosError) => {
                reject(error.response != null ? error.response.data : DEFAULT_RESPONSE);
            });
    });
};

export const GetById = <M>(url: string, id: string | number): Promise<M> => {
    return new Promise((resolve, reject) => {
        $http.get(url + '/' + id, { cancelToken: new CancelToken((c) => canceler = c) })
            .then((resp: AxiosResponse<M>) => {
                resolve(resp.data);
            })
            .catch((error: AxiosError) => {
                if(process.env.NODE_ENV == 'development') {
                    console.log(error);
                }
                reject(error.response != null ? error.response.data.error : DEFAULT_RESPONSE);
            });
    });
};

export const Post = <M, R>(url: string, model?: M, customClient?: AxiosInstance): Promise<R> => {
    return new Promise((resolve, reject) => {
        (customClient || $http).post<M, AxiosResponse<R>>(url, model, { cancelToken: new CancelToken((c) => canceler = c) } )
            .then((resp) => {
                if (resp.status >= 200 && resp.status < 300)
                    resolve(resp.data);
                else
                    reject(`Erro ${resp.status}: ${resp.statusText}`);
            })
            .catch((error: AxiosError) => {
                if(process.env.NODE_ENV == 'development') {
                    console.error(error);
                }
                reject(error.response != null ? error.response.data.error : DEFAULT_RESPONSE);
            });
    });
};

export const Patch = <M, R>(url: string = '', id: string | number, data: {[key: string]: any}): Promise<R> => {
    return new Promise((resolve, reject) => {
        $http.patch<M, void>(url + id, data, { cancelToken: new CancelToken((c) => canceler = c) })
            .then(() => {
                resolve();
            })
            .catch((error: AxiosError) => {
                if(process.env.NODE_ENV == 'development') {
                    console.log(error);
                }
                reject(error.response != null ? error.response.data.error : DEFAULT_RESPONSE);
            });
    });
};

export const GetWithCanceler = <M>(url: string = '', query?: { [key: string]: any }): [Promise<M>, Canceler| null] => {
    let requestCanceler: Canceler | null = null;

    // Pegar a query String
    const request = new Promise<M>((resolve, reject) => {
        // Realizar requisição HTTP
        $http.get(url, { params: query, cancelToken: new CancelToken((c) => requestCanceler = c) })
            .then((resp: AxiosResponse<M>) => {
                resolve(resp.data);
            })
            .catch((error: AxiosError) => {
                reject(error.response != null && error.response.data.error != null ? error.response.data.error : DEFAULT_RESPONSE);
            });
    });

    return [request, requestCanceler];
};

export const Put = <M, R>(url: string = '', data: {[key: string]: any} | null): [Promise<R>, Canceler| null] => {
    let requestCanceler: Canceler | null = null;

    const request = new Promise<R>((resolve, reject) => {
        $http.put<M, R>(url, data, { cancelToken: new CancelToken((c) => requestCanceler = c) })
            .then(() => {
                resolve();
            })
            .catch((error: AxiosError) => {
                if(process.env.NODE_ENV == 'development') {
                    console.log(error);
                }
                reject(error.response != null ? error.response.data.error : DEFAULT_RESPONSE);
            });
    });

    return [request, requestCanceler];
};