import ResponseCfcDTO from '@/models/ResponseCfcDTO';
import LocationService from '@/services/LocationService';
import distanceBetweenCoordinates from '@/utils/distanceBetweenCoordinates';
import Vue from 'vue';
import { ActionContext, Module } from 'vuex';
import { RootState } from '..';

export interface CfcFilters {
    orderBy: "avaliacoes" | "distancia";
    isAcompanhamentoDigital: boolean;
    isAulaDeficienteFisico: boolean;
    isVeiculosDeficiente: boolean;
}

export interface CfcStore {
    cfcSelecionado: ResponseCfcDTO | null;
    cfcs: ResponseCfcDTO[];
    cfcsFilters: CfcFilters;
    cfcsLoaded: boolean;
    fullWindow: boolean;
    positionFromMap: boolean;
    blockedSearchOnLoad: boolean;
}

const cfcStore: Module<CfcStore, RootState> = {
    state: {
        cfcs: [],
        cfcSelecionado: null,
        cfcsFilters: {
            orderBy:  "distancia",
            isAcompanhamentoDigital: false,
            isAulaDeficienteFisico: false,
            isVeiculosDeficiente: false,
        },
        cfcsLoaded: false,
        fullWindow: false,
        positionFromMap: false,
        blockedSearchOnLoad: false
    },
    getters: {
        GetCfcs(state: CfcStore) {
            return state.cfcs;
        },
        GetFilters(state) {
            return state.cfcsFilters;
        },
        GetCfcSelecionado(state) {
            return state.cfcSelecionado;
        },
        isCfcsLoaded(state) {
            return state.cfcsLoaded;
        },
        isFullWindow(state) {
            return state.fullWindow;
        },
        isPositionFromMap(state) {
            return state.positionFromMap;
        },
        isBlockedSearchOnLoad(state) {
            return state.blockedSearchOnLoad;
        }
    },
    mutations: {
        SetCfcs(state: CfcStore, { cfcs, position }: {cfcs: ResponseCfcDTO[]; position: [number, number]}){
            if (cfcs) {
                cfcs.forEach(cfc => {
                    Vue.set(cfc, 'distance', distanceBetweenCoordinates({ lat1: position[1], lon1: position[0], lat2: cfc.latitude, lon2: cfc.longitude }));
                });
                state.cfcs = cfcs;
                state.fullWindow = true;
            }
            else {
                state.cfcs = [];
                state.fullWindow = false;
            }
        },
        ClearCfcs(state: CfcStore){
            state.cfcs = [];
            state.cfcsLoaded = false;
            state.fullWindow = false;
        },
        SetFilters(state: CfcStore, filters: CfcFilters) {
            state.cfcsFilters = { ... filters };
        },
        SetCfcSelecionado(state: CfcStore, cfc: ResponseCfcDTO) {
            state.cfcSelecionado = { ...cfc };
        },
        setPositionFromMap(state: CfcStore, positionFromMap: boolean) {
            state.positionFromMap = positionFromMap;
        },
        setBlockedSearchOnLoad(state: CfcStore, blockedSearchOnLoad: boolean) {
            state.blockedSearchOnLoad = blockedSearchOnLoad;
        }
    },
    actions: {
        LoadCfcs({ state, getters, commit }: ActionContext<CfcStore, RootState>) {
            return new Promise((resolve, reject) => {
                state.cfcs = [];
                state.cfcsLoaded = false;
                let position: [number, number] | null = null;
                try {
                    position = getters['getCurrentPosition'];
                }
                catch(e) {
                    console.log(e);
                }
                if (position != null) {
                    state.cfcsLoaded = true;
                    LocationService.GetCfcs(position[1], position[0])
                        .then(resp => {
                            commit('SetCfcs', { cfcs: resp, position: position });
                            resolve();
                        })
                        .catch(error => reject(error));
                }
            });
        }
    }
};

export default cfcStore;