import UsuarioPostDTO from '@/DTOs/UsuarioPostDTO';
import CameraOptions from '@/enums/CameraOptionsEnum';
import AuthCredentials from '@/models/AuthCredentials';
import AuthResponse from '@/models/AuthResponse';
import $http from '@/plugins/http';
import { trackers } from '@/plugins/insights';
import UserQuery from '@/queryObjects/UserQuery';
import IntegrationService from '@/services/IntegrationService';
import NativeService from '@/services/NativeService';
import UserService from '@/services/UserService';
import { ActionContext, Module } from 'vuex';
import { RootState } from '..';
import ExternalAuthCredentials from '@/models/ExternalAuthCredentials';
import { parseJwt } from '@/utils/stringUtils';
import packageInfo from '../../../package.json';

export interface UserState {
    userForm: UsuarioPostDTO;
    syncing: boolean;
    possuiVinculoCFC: boolean;
    possuiProcessoPratico: boolean;
    appVersion: string;
}

const userStore: Module<UserState, RootState> = {
    state: {
        userForm: { 
            nome: '',
            cpf: '',
            email: '', 
            senha: '', 
            imagem: '',
            codUsuarioPilotar: '',
            estado: '',
            telefone: '',
            whatsApp: ''
        },
        syncing: false,
        appVersion: packageInfo.version,
        possuiVinculoCFC: false,
        possuiProcessoPratico: false
    },
    getters: {
        GetUserName(state: UserState){
            return state.userForm.nome;
        },
        GetAppVersion(state: UserState){
            return state.appVersion;
        },
        getUserForm(state: UserState) {
            return state.userForm;
        },
        IsSyncing(state: UserState) {
            return state.syncing;
        },
        getPossuiVinculoCFC(state: UserState){
            return state.possuiVinculoCFC;
        },
        getPossuiProcessoPratico(state: UserState){
            return state.possuiProcessoPratico;
        }
    },
    mutations: {
        SetUserForm(state: UserState, value: UsuarioPostDTO){
            const estados = new Map<string,string>([
                ['Acre', "AC"],
                ['Alagoas', "AL"],
                ['Amapá', 'AP'],
                ['Amazonas', 'AM'],
                ['Bahia', 'BA'],
                ['Ceará', 'CE'],
                ['Distrito Federal', 'DF'],
                ['Espírito Santo', 'ES'],
                ['Goiás', 'GO'],
                ['Maranhão', 'MA'],
                ['Mato Grosso', 'MT'],
                ['Mato Grosso do Sul', 'MS'],
                ['Minas Gerais', 'MG'],
                ['Pará', 'PA'],
                ['Paraíba', 'PB'],
                ['Paraná', 'PR'],
                ['Pernambuco', 'PE'],
                ['Piauí', 'PI'],
                ['Rio de Janeiro', 'RJ'],
                ['Rio Grande do Norte', 'RN'],
                ['Rio Grande do Sul', 'RS'],
                ['Rondônia', 'RO'],
                ['Roraima', 'RR'],
                ['Santa Catarina', 'SC'],
                ['São Paulo', 'SP'],
                ['Sergipe', 'SE'],
                ['Tocantins', 'TO'],
            ]);

            state.userForm = {
                codUsuarioPilotar: '',
                nome: value.nome,
                cpf: value.cpf.replace(/[^0-9]+/g,''),
                email: value.email,
                estado: estados.get(value.estado)!,
                imagem: value.imagem,
                senha: value.senha,
                telefone: value.telefone,
                whatsApp: value.telefone
            };

        },
        setPossuiVinculoCFC(state: UserState, value: boolean){
            state.possuiVinculoCFC = value;
        },
        setPossuiProcessoPratico(state: UserState, value: boolean){
            state.possuiProcessoPratico = value;
        }
    },
    actions: {
        RedefinePassword(_: ActionContext<UserState, RootState>, email: string) {
            return new Promise((resolve, reject) => {
                UserService.RedefinirSenha(email)
                    .then((resp) => {
                        resolve(resp);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        CheckEmail({ dispatch }: ActionContext<UserState, RootState>, email: string) {
            return new Promise((resolve, reject) => {
                dispatch('VerifyUser', { email: email })
                    .then(resp => resp.length > 0 ?
                        reject(`$USERNAME, parece que esse e-mail já está sendo utilizado. Caso esteja com dificuldades para efetuar login com seu usuário, entre em contato com o suporte, ou então, insira um novo e-mail.`) :
                        resolve("Tudo certo, para finalizarmos, preciso pegar o seu CPF e te pedir para criar uma senha. Esses serão os seus dados de acesso para o login. 😉"))
                    .catch(() => reject('Tivemos um pequeno problema, poderia informar seu e-mail novamente, por favor'));
            });
        },
        CheckCpf({ dispatch }: ActionContext<UserState, RootState>, cpf: string) {
            return new Promise((resolve, reject) => {
                dispatch('VerifyUser', { cpf: cpf.replace(/[^0-9]+/g,'') })
                    .then(resp => resp.length > 0 ?
                        reject(`$USERNAME, parece que esse CPF já está sendo utilizado, verifique se o número informado está correto. Caso esteja com dificuldades para efetuar login com seu usuário, entre em contato com o suporte.`) :
                        resolve("Beleza, seu CPF já está confirmado no nosso sistema"))
                    .catch(() => reject('Tivemos um pequeno problema, poderia informar seu e-mail novamente, por favor'));
            });
        },
        VerifyUser( _: ActionContext<UserState, RootState>, query: UserQuery){
            return new Promise((resolve, reject) => {
                UserService.GetUsuarios(query.email, query.cpf)
                    .then((resp) => {
                        
                        // setar o basic token no header do http.
                        const user = resp.find(res => { if (res.email == query.email || res.cpf == query.cpf) return res.basicAuthSp; });
                        localStorage.setItem('session#pilotarapp#tokenBasic', JSON.stringify(user?.basicAuthSp!));
                        $http.defaults.headers = { accessToken :  user?.basicAuthSp };
                        
                        resolve(resp);
                    })
                    .catch((error) => {
                        reject(error);
                        $http.defaults.headers = { accessToken : '' };
                    });
            });
        },
        SignUp( { state }: ActionContext<UserState, RootState>){
            return new Promise((resolve, reject) => {
                UserService.CriarConta(state.userForm)
                    .then((resp) => {
                        resolve(resp);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        SignIn({ commit, rootState, dispatch }: ActionContext<UserState, RootState>, credentials: AuthCredentials){
            return new Promise((resolve, reject) => {
                UserService.Login(credentials)
                    .then((user) => {
                        if (user.pilotar?.token == null) {
                            reject("Usuário ou senha inválidos");
                        }
                        else {
                            rootState.userType = "alunoRegistrado";
                            localStorage.setItem('session#pilotarapp', JSON.stringify(user));
                            localStorage.setItem("TOKENAPI", user.token);
                            localStorage.setItem("session#pilotarapp#tokenBasic", user.basicAuthSp);
                            localStorage.setItem("session#pilotarapp#token", user.pilotar?.token);

                            const jwtPayload: any = parseJwt(user.token);
                            let audience: string = "studentApp";
                            if (jwtPayload && jwtPayload.aud)
                                audience = jwtPayload.aud;

                            localStorage.setItem("session#pilotarapp#audience", "studentApp");
    
                            // App Insights
                            trackers.registerUserSignIn();
    
                            commit('setUser', user);
                            resolve(user);
                            dispatch('Sync', user);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        ExternalSignIn({ commit, rootState, dispatch }: ActionContext<UserState, RootState>, credentials: ExternalAuthCredentials){
            return new Promise((resolve, reject) => {
                UserService.ExternalLogin(credentials)
                    .then((user) => {
                        if (user.pilotar?.token == null) {
                            reject("Usuário ou senha inválidos");
                        }
                        else {
                            rootState.userType = "alunoRegistrado";
                            localStorage.setItem('session#pilotarapp', JSON.stringify(user));
                            localStorage.setItem("TOKENAPI", user.token);
                            localStorage.setItem("session#pilotarapp#tokenBasic", user.basicAuthSp);
                            localStorage.setItem("session#pilotarapp#token", user.pilotar?.token);

                            const jwtPayload: any = parseJwt(user.token);
                            let audience: string = "painel";
                            if (jwtPayload && jwtPayload.aud)
                                audience = jwtPayload.aud;

                            localStorage.setItem("session#pilotarapp#audience", "painel");
    
                            // App Insights
                            trackers.registerUserSignIn();
    
                            commit('setUser', user);
                            resolve(user);
                            dispatch('Sync', user);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        Sync({ state, commit, dispatch }, user: AuthResponse){
            state.syncing = false;
            if(user.cfcId == 0) {
                state.syncing = true;
                IntegrationService.Sync(user.cpf, user.estado)
                    .then(resp => {
                        if(resp.usuario != null) {
                            commit('setUser', resp.usuario);
                            return resp.usuario.alunoSuperPratico ? dispatch('loadClasses') : Promise.resolve();
                        }
                        else {
                            return Promise.resolve();
                        }
                    })
                    .finally(() => {
                        state.syncing = false;
                    });
            }
        },
        updateAvatar({ rootState }){

            return new Promise((resolve, reject) => {
                const updateAvatarImage = (base64: string) => {
                    // Realizar requisição
                    const [request] = UserService.UpdateAvatar(rootState.user!.id, base64);
                    request
                        .then(() => {
                            // Mudar Imagem
                            rootState.user!.imagem = `data:image/png;base64, ${base64}`;
                            resolve(base64);
                        }) 
                        .catch((response) => {
                            reject(response);
                        });
                };    
    
                const DEBUG = false;
                if (DEBUG) {
                    console.log("DEBUG");
                    //Utilizar esse espaço para inserir manualmente uma imagem, em ambiente de desenvolvimento
                    updateAvatarImage('');

                }
                else {
                    if(rootState.user != null) {
                        NativeService.TakePicture(CameraOptions.Front)
                            .then(base64 => {
                                updateAvatarImage(base64);
                            });
                    }
                }                
            });
        },
    }
};

export default userStore;
