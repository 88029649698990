import { Module } from 'vuex';
import { NavigationItem } from '@/interfaces/NavigationItem';
import $router from '@/router';
import { RootState } from '..';

export interface NavigationState {
    navigationStack: NavigationItem[];
    transition: string;
    showToast: boolean;
    navigating: boolean;
    homeMenuIndex: number;
}

const navigationStore: Module<NavigationState, RootState> = {
    state: {
        navigationStack: [],
        transition: 'crossFade',
        showToast: false,
        navigating: false,
        homeMenuIndex: 0
    },
    getters: {
        showToast(state) {
            return state.showToast;
        },
        canGoBack(state): boolean {
            return state.navigationStack.length > 0;
        },
        getTransition(state): string {
            return state.transition;
        },
        getHomeMenuIndex(state) {
            return state.homeMenuIndex;
        }
    },
    mutations: {
        setHomeMenuIndex(state, index: number) {
            state.homeMenuIndex = index;
        },
        navigate(state, page: NavigationItem): void {
            if(!state.navigating) {
                const currentPage = state.navigationStack[state.navigationStack.length - 1];
                if(currentPage == null || (currentPage != null && currentPage.page != page.page)) {
                    state.navigating = true;
                    state.navigationStack.push(page);
                    state.transition = page.transition;
                    setTimeout(() => {
                        state.navigating = false;
                    }, 300);
                    if(page.isPath) {
                        $router.push({ path: page.page, params: page.params, query: page.query });
                    }
                    else {
                        $router.push({ name: page.page, params: page.params, query: page.query });
                    }
                }
            }
        },
        replace(state, page: NavigationItem) {
            const lastPage: NavigationItem = state.navigationStack[state.navigationStack.length - 1];
            if(lastPage != null) {
                state.navigationStack[state.navigationStack.length - 1] = { 
                    page: page.page,
                    transition: lastPage.transition,
                    isPath: page.isPath,
                    params: page.params,
                    query: page.query
                };
            }
            state.transition = page.transition;
            if(page.isPath) {
                $router.replace({ path: page.page, query: page.query, params: page.params });
            }
            else {
                $router.replace({ name: page.page, query: page.query, params: page.params });
            }
        },
        clearStack(state, page: NavigationItem) {
            state.navigationStack = [];
            state.transition = page.transition;
            $router.replace({ name: page.page });
        },
        goBack(state): void {
            if(state.navigationStack.length) {
                const lastPage = state.navigationStack[state.navigationStack.length - 1];
                state.transition = lastPage.transition + 'Reverse';
                $router.go(-1);
                state.navigationStack.pop();
            }
            else {
                // NativeService.CloseApplication();
                state.showToast = true;
                setTimeout(() => {
                    state.showToast = false;
                }, 3000);
            }
        }
    }
};

export default navigationStore;