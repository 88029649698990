import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { trackers } from '@/plugins/insights';
Vue.use(Vuex);

// State
export interface RootState {
    position: [number, number] | null;
    user: AuthResponse | null;
    userType: 'alunoRegistrado' | 'alunoNaoRegistrado' | 'naoAluno' | null;
    usingCurrentPosition: boolean;
    userAllowedPosition: boolean;
    currentPositionAddress: string | null;
    searchValue: string | null;
}

// Modulos
import userStore from './modules/userStore';
import avaliacaoStore from './modules/avaliacaoStore';
import simuladoTeoricoStore from './modules/simuladoTeoricoStore';
import cfcStore from './modules/cfcStore';
import navigationStore from './modules/navigationStore';
import locationStore from './modules/locationStore';
import integrationStore from './modules/integrationStore';
import coreStore from './modules/coreStore';
import AuthService from '@/services/AuthService';

import AuthResponse from '@/models/AuthResponse';

const store: StoreOptions<RootState> = {
    modules: {
        userStore,
        avaliacaoStore,
        simuladoTeoricoStore,
        cfcStore,
        navigationStore,
        locationStore,
        integrationStore,
        coreStore
    },
    state: {
        position: null,
        user: null,
        userType: null,
        usingCurrentPosition: false,
        currentPositionAddress: null,
        searchValue: null,
        userAllowedPosition: false
    },
    getters: {
        userRegistered(state: RootState) {
            return state.user != null;
        },
        alunoSuperPratico(state) {
            return state.user != null && state.user.alunoSuperPratico;
        },
        getUser(state: RootState) {
            return state.user;
        },
        removerAcento() {
            return (value: string) => {
                value = value.toLowerCase().split(' ').join('_');                                                         
                value = value.replace(/[ÁÀÂÃ]/gi, 'a');
                value = value.replace(/[ÉÈÊ]/gi, 'e');
                value = value.replace(/[ÍÌÎ]/gi, 'i');
                value = value.replace(/[ÓÒÔÕ]/gi, 'o');
                value = value.replace(/[ÚÙÛ]/gi, 'u');
                value = value.replace(/Ç/gi, 'c');
                return value;  
            };
        },
        getCurrentPosition(state: RootState) {
            return state.position;
        },
        getUserType(state) {
            return state.userType;
        },
        isStudent(state) {
            return state.userType != null && state.userType.startsWith('aluno');
        },
        isUsingCurrentPosition(state) {
            return state.usingCurrentPosition;
        },
        getCurrentPositionAddress(state) {
            return state.currentPositionAddress;
        },
        getSearchValue(state) {
            return state.searchValue;
        },
        getUserAllowedPosition(state) {
            return state.userAllowedPosition;
        }
    },
    mutations: {
        setUser(state: RootState, user: AuthResponse) {
            state.user = user;
        },
        setUserType(state, userType: 'alunoRegistrado' | 'alunoNaoRegistrado' | 'naoAluno' | null,) {
            state.userType = userType;
        },
        logout(state: RootState) {
            (async() => {

                // App Insights
                trackers.registerUserSignOut();
    
                state.position = [0, 0];
                state.user = null;
                state.userType = null;

                localStorage.removeItem('TOKENAPI');
                localStorage.removeItem('session#pilotarapp#audience');
                localStorage.removeItem('session#pilotarapp#token');
                localStorage.removeItem('session#pilotarapp');
                localStorage.removeItem('balloon#pilotarapp');
                localStorage.removeItem('session#pilotarapp#tokenBasic');
                localStorage.removeItem('usertype#pilotarapp');

                try{
                    await AuthService.logout();
                }
                catch(e){
                    console.error("Erro ao realizar logout");
                }

                document.location.href = "/main/autoescolas";
            })();
        },
        clearSession(state: RootState) {
            state.position = [0, 0];
            state.user = null;
            state.userType = null;
            
            localStorage.removeItem('TOKENAPI');
            localStorage.removeItem('session#pilotarapp#audience');
            localStorage.removeItem('session#pilotarapp#token');
            localStorage.removeItem('session#pilotarapp');
            localStorage.removeItem('balloon#pilotarapp');
            localStorage.removeItem('session#pilotarapp#tokenBasic');
            localStorage.removeItem('usertype#pilotarapp');
        },
        setCurrentPosition(state: RootState, coordinates: [number, number]) {
            localStorage.setItem('getlocationallowed#pilotarapp', "true");
            state.position = coordinates;
        },
        setUsingCurrentPosition(state: RootState, usingCurrentPosition: boolean) {
            state.usingCurrentPosition = usingCurrentPosition;
        },
        setCurrentPositionAddress(state: RootState, currentPositionAddress: string | null) {
            state.currentPositionAddress = currentPositionAddress;
        },
        setSearchValue(state: RootState, searchValue: string | null) {
            state.searchValue = searchValue;
        },
        setUserAllowedPosition(state: RootState, userAllowedPosition: boolean) {
            state.userAllowedPosition = userAllowedPosition;
        }        
    }
};

const vuex = new Vuex.Store(store);

vuex.watch(state => state.position, (newValue) => {
    localStorage.setItem('coordinates#pilotarapp', JSON.stringify(newValue));
}, { deep: true });

vuex.watch(state => state.userType, (newValue) => {
    newValue != null ? localStorage.setItem('usertype#pilotarapp', newValue) : localStorage.removeItem('usertype#pilotarapp');
}, { deep: true });

export default vuex;
