import { Rating, CreateRating } from '@/models/Rating';
import { trackers } from '@/plugins/insights';
import RatingsService from '@/modules/rating/services/RatingsService';
import integracaoStore from '@/store/modules/integrationStore';
import { removeCnpjMask, removeCpfMask } from '@/utils/stringUtils';
import { Module } from 'vuex';
import { RootState } from '..';

export interface AvaliacaoState {
    avaliacaoAulaPratica: Rating | null;
}

const avaliacaoStore: Module<AvaliacaoState, RootState> = {
    state: {
        avaliacaoAulaPratica: null
    },
    getters: {
        getAvaliacaoAulaPratica(state) {
            return state.avaliacaoAulaPratica;
        }
    },
    mutations: {
        setAvaliacaoAulaPratica(state, avaliacao: Rating) {
            state.avaliacaoAulaPratica = avaliacao;
        },
        clearAvaliacaoAulaPratica(state) {
            state.avaliacaoAulaPratica = null;
        }
    },
    actions: {
        LoadAvaliacaoAulaPratica({ state, commit }, rating: Rating){
            state.avaliacaoAulaPratica = null;
            commit('setAvaliacaoAulaPratica', rating);
        },
        PostAvaliacaoAulaPratica({ rootState, getters, commit }, data: { codAulaPratica: string; comentario: string; tags: string[]; nota: number; cnpjCfc: string; nomeCfc: string; estado: string }){

            const classe: any = (integracaoStore.state as any).classes.find((classe: any) => classe.oid === data.codAulaPratica);

            return new Promise((resolve, reject) => {

                const rating: CreateRating = {
                    author: {
                        code: rootState.user!.cpf,
                        name: rootState.user!.nome
                    },
                    item: data.codAulaPratica,
                    tags: data.tags,
                    comment: data.comentario,
                    value: data.nota,
                    metadata: {
                        instrutor: {
                            nome: getters['getNomeInstrutor'],
                            cpf: removeCpfMask(classe.cpfInstrutor)
                        },
                        cfc: {
                            cnpj: removeCnpjMask(data.cnpjCfc),
                            nome: data.nomeCfc
                        },
                        aluno: {
                            uf: data.estado
                        },
                        superPraticoAuthorization: localStorage.getItem("session#pilotarapp#tokenBasic")
                    },
                    providerId: process.env.VUE_APP_PROVIDER_ID,
                    surveyId: process.env.VUE_APP_SURVEY_ID
                };

                RatingsService.createRating(rating)
                    .then((resp) => {
                        // Insights
                        trackers.registerRatedLesson();


                        const ratingResp = { ... rating, id: resp?.id };
                        
                        commit('setAulaAvaliada', ratingResp);
                        commit('setAvaliacaoAulaPratica', ratingResp);
                        resolve(ratingResp);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    }
};

export default avaliacaoStore;