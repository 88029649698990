import SimuladoTeoricoService from '@/services/SimuladoTeoricoService';
import Tema from '@/models/Tema';
import Questao from '@/models/Questao';
import RequestSimulado from '@/models/RequestSimulado';
import Vue from 'vue';
import { Module, ActionContext } from 'vuex';
import { RootState } from '..';
import EstatisticaTeorico from '@/models/EstatisticaTeorico';
import AuthResponse from '@/models/AuthResponse';
import { trackers } from '@/plugins/insights';

export interface SimuladoTeoricoState {
    temas: Tema[];
    temaSelecionado: number;
    loading: boolean;
    quantidadeQuestoes: number;
    duracaoSimulado: number;
    tempoFinalSimulado: number;
    questoes: Questao[];
    porcentagemAcertoUsuario: number;
    estatisticasTeorico: EstatisticaTeorico;
    visualizouRespostas: boolean;
}

const simuladoTeoricoStore: Module<SimuladoTeoricoState, RootState> = {
    state: {
        temas: [],
        temaSelecionado: -1,
        loading: false,
        quantidadeQuestoes: 5,
        duracaoSimulado: 10,
        tempoFinalSimulado: 0,
        questoes: [],
        porcentagemAcertoUsuario: 0,
        estatisticasTeorico: {
            porcentagemAcerto: 0,
            quantidadeAprovacoes: 0,
            quantidadeQuestoes: { corretas: 0, erradas: 0, respondidas: 0 },
            quantidadeQuestoesPorTema: [],
            quantidadeReprovacoes: 0
        },
        visualizouRespostas: false
    },
    getters: {
        GetLoadingSimulado(state: SimuladoTeoricoState){
            return state.loading;
        },
        GetTemas(state: SimuladoTeoricoState){
            return state.temas;
        },
        GetQuestoes(state: SimuladoTeoricoState): Questao[] {
            return state.questoes;
        },
        GetQuantidadeQuestoesCorretas(state: SimuladoTeoricoState): number {
            const questoesCorretas = state.questoes.filter(q => {
                const alternativaCorreta = q.alternativas.find(al => al.alternativaCorreta);
                return alternativaCorreta!.id == q.alternativaSelecionada;
            });
            return questoesCorretas.length;
        },
        GetDuracaoSimulado (state: SimuladoTeoricoState): number {
            return state.duracaoSimulado * 60000;
        },
        GetTempoSimulado(state: SimuladoTeoricoState): string {
            const tempoRealizado = (state.duracaoSimulado * 60000) - state.tempoFinalSimulado;
            const minutes = Math.trunc((tempoRealizado % 3600000) / 60000);
            const seconds = Math.trunc(((tempoRealizado % 3600000) % 60000) / 1000);
    
            return minutes + ':' + seconds.toString().padStart(2, '0');
        },
        GetPorcentagemAcertoUsuario(state: SimuladoTeoricoState) {
            return state.porcentagemAcertoUsuario;
        },
        GetEstatisticasSimulado(state: SimuladoTeoricoState) {
            return state.estatisticasTeorico;
        },
        GetVisualizouRespostas(state: SimuladoTeoricoState) {
            return state.visualizouRespostas;
        }
    },
    mutations: {
        SetPorcentagemAcertoUsuario(state: SimuladoTeoricoState, value: number){
            state.porcentagemAcertoUsuario = value;
        },
        SetLoading(state: SimuladoTeoricoState, value: boolean) {
            state.loading = value;
        },
        SetTemas(state: SimuladoTeoricoState, temas: Tema[]) {
            state.temas = temas;
        },
        SetDadosTema(state: SimuladoTeoricoState, dados: { id: number; questoes: number; duracao: number}) {
            state.temaSelecionado = dados.id;
            state.quantidadeQuestoes = dados.questoes;
            state.duracaoSimulado = dados.duracao;
        },
        SetQuestoes(state: SimuladoTeoricoState, questoes: Questao[]) {
            questoes.forEach(questao => Vue.set(questao, 'alternativaSelecionada', 0));
            state.questoes = questoes;
        },
        SetTempoFinalSimulado(state: SimuladoTeoricoState, tempo: number) {
            state.tempoFinalSimulado = tempo;
        },
        SetEstatisticasTeorico(state: SimuladoTeoricoState, value: EstatisticaTeorico) {
            state.estatisticasTeorico = value;
        },
        SetVisualizouRespostas(state: SimuladoTeoricoState, value: boolean) {
            state.visualizouRespostas = value;
        }
    },
    actions: {
        async ResponderSimulado( { state, commit, getters }: ActionContext<SimuladoTeoricoState, RootState>) {
            commit('SetLoading', true);
            try{
                // Requisição
                const requestBody: RequestSimulado = {
                    usuarioId: (getters['getUser'] as AuthResponse).id,
                    percentualAprovacao: 70,
                    respostasUsuario: state.questoes.map(q => {
                        return {
                            alternativaId: q.alternativaSelecionada,
                            questaoId: q.id,
                            temaId: q.temaId
                        };
                    })
                };
                const res =  await SimuladoTeoricoService.ResponderSimulado(requestBody);
                commit('SetPorcentagemAcertoUsuario', res.percentualAcertoUsuario);
            }catch(err){
                console.log(err);
            }finally {
                commit('SetLoading', false);
            }
        },
        async GetTemasApi({ commit }: ActionContext<SimuladoTeoricoState, RootState>) {
            commit('SetLoading', true);
            commit('SetTemas', []);
            try{
                const temas: Tema[] = await SimuladoTeoricoService.GetTemas();
                commit('SetTemas', temas);
            }catch(err){
                console.log(err);
            }finally{
                commit('SetLoading', false);
            }
        },
        async GetQuestoesApi({ state, commit }: ActionContext<SimuladoTeoricoState, RootState>) {
            commit('SetLoading', true);
            try{
                let temas: number[] = [];
                if(state.temaSelecionado == 99){
                    temas = state.temas.map(t => t.id);
                }else {
                    temas.push(state.temaSelecionado);
                }
                const questoes = await SimuladoTeoricoService.GetQuestoes(state.quantidadeQuestoes, temas);

                // Registrar no App Insights
                trackers.registerStartedThericTest();
                commit('SetQuestoes', questoes);
            }catch(err){
                console.log(err);
            }finally{
                commit('SetLoading', false);
            }
        },
        GetEstatisticasApi({ commit, rootState }: ActionContext<SimuladoTeoricoState, RootState>) {
            return new Promise((resolve, reject) => {
                commit('SetLoading', true);
                SimuladoTeoricoService.GetEstatisticasTeorico(rootState.user!.id)
                    .then(estatisticas => {
                        commit('SetEstatisticasTeorico', estatisticas);
                        resolve();
                    })
                    .catch(error => {
                        if(error.status == 404){
                            resolve();
                        }
                        else {
                            reject(error.error);

                        }
                    })
                    .finally(() => {
                        commit('SetLoading', false);
                    });
            });
        }
    }
};

export default simuladoTeoricoStore;