import { Get, Post, Put } from './BaseService';
import AuthCredentials from '@/models/AuthCredentials';
import AuthResponse from '@/models/AuthResponse';
import UsuarioDTO from '@/DTOs/UsuarioDTO';
import ExcluirContaDTO from '@/DTOs/ExcluirContaDTO';
import UsuarioPostDTO from '@/DTOs/UsuarioPostDTO';
import { deviceId } from '@/utils/stringUtils';
import ExternalAuthCredentials from '@/models/ExternalAuthCredentials';

export default class UserService {

    public static BaseUrl = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_USUARIO_API_URL}`;
    
    public static Login(credentials: AuthCredentials): Promise<AuthResponse> {
        credentials.deviceId = deviceId();
        return Post<AuthCredentials, AuthResponse>(`${this.BaseUrl}/auth`, credentials);
    }

    public static ExternalLogin(credentials: ExternalAuthCredentials): Promise<AuthResponse> {
        return Post<ExternalAuthCredentials, AuthResponse>(`${this.BaseUrl}/auth/external`, credentials);
    }

    public static CriarConta(usuario: UsuarioPostDTO): Promise<UsuarioDTO> {
        return Post<UsuarioPostDTO, UsuarioDTO>(`${this.BaseUrl}/usuarios`, usuario);
    }

    public static ExcluirConta(motivo: string, comentarios: string) {
        return Put<ExcluirContaDTO, void>(`${this.BaseUrl}/usuarios/cancel-account`, { "motivo": motivo, "comentarios": comentarios } );
    }

    public static GetUsuarios(email: string = '', cpf: string = '') {
        return Get<UsuarioDTO[]>(`${this.BaseUrl}/usuarios`, { email, cpf });
    }

    public static RedefinirSenha(email: string) {
        return Post<{ email: string }, void>(`${this.BaseUrl}/senhas/forgot`, { email });
    }

    public static UpdateAvatar(id: number, avatar: string) {
        return Put<{imagem: string}, void>(`${this.BaseUrl}/usuarios/${id}/update-image`, { image: avatar });
    }
}