import { AulaDetailDTO } from '@/DTOs/AulaDetailDTO';
import ClassRouteDTO from '@/DTOs/ClassRouteDTO';
import TipoOcorrenciaEnum from '@/enums/TipoOcorrenciaEnum';
import AulaDTO from '@/models/AulaDTO';
import { Rating } from "@/models/Rating";
import IntegrationService from '@/services/IntegrationService';
import Vue from 'vue';
import { ActionContext, Module } from "vuex";
import { RootState } from '..';

export interface Percurso {
    coordinates: [number, number];
    type: TipoOcorrenciaEnum;
    descricao?: string;
    desempenho?: string;
}

export interface ClassDetail {
    instrutor: string;
    inicio: string;
    fim: string;
    tempo: string;
    codAula: string;
    veiculo: string;
    processo: string;
    autoescola: string;
    percurso: Percurso[];
    idImages: string[];
    distancia: string;
}

interface IntegracaoStore {
    classes: AulaDTO[];
    loadingClasses: boolean;
    classDetail: ClassDetail | null;
}

const integrationStore: Module<IntegracaoStore, RootState> = {
    state: {
        classes: [],
        loadingClasses: false,
        classDetail: null,
    },
    getters: {
        loadingClasses(state){
            return state.loadingClasses;
        },
        getClassesLength(state) {
            return state.classes?.length || 0;
        },
        getClasses(state) {
            return state.classes;
        },
        getEvaluatedClasses(state) {
            return state.classes?.filter(el => el.media != null) || 0;
        },
        getNonEvaluatedClasses(state) {
            return state.classes?.filter(el => el.media == null) || 0;
        },
        getClassDetail(state) {
            return state.classDetail;
        },
        getNomeInstrutor(state) {
            return state.classDetail != null ? state.classDetail.instrutor : '';
        },
        getConteudos(state) {
            if(state.classDetail != null) {
                return state.classDetail.percurso.filter(el => el.type == TipoOcorrenciaEnum.Conteudo);
            }  
            else {
                return [];
            }
        },
        getFaltas(state) {
            if(state.classDetail != null) {
                return state.classDetail.percurso.filter(el => el.type == TipoOcorrenciaEnum.Falta);
            }  
            else {
                return [];
            }
        }
    },
    mutations: {
        setClasses(state, classes: AulaDTO[]) {
            state.classes = classes;
        },
        setClassDetail(state, classDetail: AulaDetailDTO) {
            state.classDetail = {
                codAula: classDetail.data.find(el => el.label == 'Número')?.value || '-',
                autoescola: classDetail.data.find(el => el.label == 'CFC')?.value || '-',
                fim: classDetail.data.find(el => el.label == 'Fim')?.value || '-',
                idImages: classDetail.imagens,
                inicio: classDetail.data.find(el => el.label == 'Início')?.value || '-',
                instrutor: classDetail.data.find(el => el.label == 'Instrutor')?.value || '-',
                percurso: [],
                veiculo: classDetail.data.find(el => el.label == 'Veículo')?.value || '-',
                processo: classDetail.data.find(el => el.label == 'Processo')?.value || '-',
                tempo: '',
                distancia: classDetail.data.find(el => el.label == 'Distância percorrida')?.value || '-',
            };
        },
        setClassRoute(state, classRoute: ClassRouteDTO) {
            state.classDetail!.percurso = classRoute.data.map<Percurso>(el => {
                return {
                    coordinates: [el.longitude, el.latitude],
                    type: el.tipoOcorrencia,
                    descricao: el.descricao,
                    desempenho: el.desempenho
                };
            });
        },
        setAulaAvaliada(state, rating: Rating) {
            const classe = state.classes.find(el => el.oid == rating.item);
            if(classe) {
                classe.rating = rating;
                Vue.set(classe, 'media', rating.value);
                Vue.set(classe, 'avaliacaoId', rating.id);
            }
        }
    },
    actions: {
        loadClasses({ rootState, state, commit }: ActionContext<IntegracaoStore, RootState>) {
            return new Promise((resolve, reject) => {
                state.classes = [];
                if(rootState.user != null) {
                    state.loadingClasses = true;
                    
                    IntegrationService.GetClasses(rootState.user.cpf, rootState.user.estado)
                        .then(resp => {
                            commit('setClasses', resp);
                            resolve("");
                        })
                        .catch(error => {
                            console.log(error);
                            reject(error);
                        })
                        .finally(() => state.loadingClasses = false);
                }
                else {
                    reject('Usuário inválido');
                }
            });
        },
        loadClassDetail({ commit, state, dispatch, rootState }: ActionContext<IntegracaoStore, RootState>, oid: string) {
            return new Promise((resolve, reject) => {
                state.classDetail = null;
                commit('clearAvaliacaoAulaPratica');
                // Carregar detalhes
                IntegrationService.GetClassDetail(oid, rootState.user!.cpf, rootState.user!.estado)
                    .then(detail => {
                        commit('setClassDetail', detail);
                        // Carregar rotas
                        return IntegrationService.GetClassRoute(oid, rootState.user!.cpf, rootState.user!.estado);
                    })
                    .then(route => {
                        commit('setClassRoute', route);
                        const rating = state.classes.find((classe: any) => classe.oid == oid)?.rating;
                        return rating ? dispatch('LoadAvaliacaoAulaPratica', rating) : Promise.resolve();
                    })
                    .then(() => resolve(""))
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        loadImage({ rootState }: ActionContext<IntegracaoStore, RootState>, oid: string) {
            return new Promise((resolve, reject) => {
                IntegrationService.GetImage(oid, rootState.user!.cpf, rootState.user!.estado)
                    .then(resp => resolve(resp))
                    .catch(error => reject(error));
            });
        }
    }
};

export default integrationStore;