
import NativeService from '@/services/NativeService';
import { RootState } from '..';
import { Module } from 'vuex';
import ResponseCfcDTO from '@/models/ResponseCfcDTO';

export interface LocationStore {
    selectedLocation: ResponseCfcDTO | null;
    mapPosition: [number, number];
}

const locationStore: Module<LocationStore, RootState> = {
    state: {
        selectedLocation: null,
        mapPosition: [-34.861, -7.11532],
      
    },
    getters: {
        GetSelectedLocation(state: LocationStore) {
            return state.selectedLocation;
        },
        getMapPosition(state: LocationStore) {
            return state.mapPosition;
        }
    },
    mutations: {
        SetSelectedLocation(state: LocationStore, location: ResponseCfcDTO) {
            state.selectedLocation = location;
        },
        SetMapPosition(state: LocationStore, position: [number, number]) {
            state.mapPosition = position;
            localStorage.setItem("mapposition", JSON.stringify(position));
        }
    },
    actions: {
        LoadCurrentPosition(): Promise<number[]> {
            return new Promise((resolve, reject) => {
                NativeService.GetCurrentPosition()
                    .then(resp => {
                        resolve(resp);
                    })    
                    .catch(error => {
                        reject(error);
                    });
            });
        },
    }
};

export default locationStore;