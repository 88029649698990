import { Post } from './BaseService';
import httpPilotar from '@/plugins/httpPilotar';

export default class AuthService{

    public static BaseUrl = process.env.VUE_APP_API_FIREBASE_URL;

    public static logout() {
        return Post(`${this.BaseUrl}/auth/users/logout`, null, httpPilotar);
    }
}