import http from '@/plugins/http';
import axios, { AxiosResponse } from 'axios';

const baseUrl = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_AUTORIZACAO_API_URL}`;

export default class AutorizacaoService {

    public static getToken()
    {        
        return localStorage.getItem("TOKENAPI") ?? "";
    } 
    
    public static getPilotarFirebaseToken()
    {        
        return localStorage.getItem("session#pilotarapp#token") ?? "";
    } 
    
    public static getTokenBasic()
    {        
        return localStorage.getItem("session#pilotarapp#tokenBasic") ?? "";
    } 
    
    public static validar(tokenReq: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let token = '';
            axios.get(`${baseUrl}/auth/validar?token=` + tokenReq)
                .then((res: AxiosResponse) => {
                    if (res?.data?.token == null) {
                        reject('Token inválido');
                    }
                    else {
                        token = res.data.token;
                        resolve(token);
                        localStorage.setItem('TOKENAPI', token);
                        // setar token no authorization do plugin http
                        http.defaults.headers = {
                            'Authorization' : `Bearer ${token}`
                        };
                    }
                }).catch(() => { 
                    reject('Token inválido');
                    console.error( "token inválido");
                });
        });
       
    }        
}